<template>
  <div class="visitor-in">
    <div class="container">
      <div class="content-wrap">
        <div class="visitor-vh-title">车辆登记</div>
        <van-field
          class="vhNumTit"
          v-model="vh_p_number"
          center
          clearable
          label="车牌号码"
          disabled
          placeholder="车牌号码"
          required
        ></van-field>
        <van-field
          v-model="visitor_name"
          center
          clearable
          label="访客姓名"
          placeholder="请输入访客姓名"
          required
        ></van-field>
        <van-field
          v-model="visitor_phone"
          center
          clearable
          label="联系电话"
          placeholder="请输入访客联系电话"
          required
        ></van-field>
        <van-field
          v-model="visitor_comment"
          center
          type="textarea"
          clearable
          label="事由"
          placeholder=""
          autosize
          rows="1"
        ></van-field>
        <van-button style="width: 100%" type="info" @click="doAdd"
          >确定</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import * as api from '../../../api/api'
// import * as util from '../../../api/util'
import * as common from '../../../api/common'
import * as mqtt from '../../../api/mqtt/wsconnect'
import { Field } from 'vant'
// import adver from './components/adver'
export default {
  name: 'vh_visitor_in',
  props: [],
  components: {
    // 'adver': adver,
    vanField: Field
  },
  data () {
    return {
      show: false,
      in_uid: '',
      vh_p_number: '',
      visitor_name: '',
      visitor_phone: '',
      visitor_comment: '',
      getChannelVh: 'vh_in_visitor.get_vh_in_wait_info_by_channel_id',
      getResultVh: 'vh_in_visitor.add_visitor_info_with_in_uid'
    }
  },
  methods: {
    async init_mqtt () {
      const client = await mqtt.getClient()
      if (!client.connected) {
        // this.$toast.clear()
        this.$toast.fail('NetWork err!')
        throw (new Error('NetWork err!'))
      } else {
        return client
      }
    },
    async subscribe (action) {
      const topic = `/ypt/u_${this.$route.query.sid}/${action}`
      const options = {
        payload: {
          topic: action
        }
      }
      await mqtt.subscribe(topic, options)
    },
    getResult (topic, msg) {
      if (msg.code === common.resCode.OK && Array.isArray(msg.list) && msg.list.length) {
        this.in_uid = msg.list[0].in_uid
        this.vh_p_number = msg.list[0].vh_p_number
        this.$toast.clear()
      } else {
        this.in_uid = ''
        this.vh_p_number = ''
        this.$toast.fail('未获取到通道内的待入场的访客车牌')
      }
    },
    async doGetChannelVh () {
      const action = this.getChannelVh
      try {
        this.$toast.loading({
          mask: true,
          message: '正在请求通道内访客车...'
        })
        const client = await this.init_mqtt()
        await this.subscribe(action)
        client.callback[action] = this.getResult
        const res = await axios({
          url: common.cloudParkingUrl + '/s2p/' + api.urlList.get_vh_in_wait_info_by_channel_id,
          method: 'POST',
          data: {
            sid: this.$route.query.sid,
            channel_path: this.$route.query.channel_path,
            version: 'v2'
          },
          header: {
            // 'content-type': 'application/json' // 默认值
            'Content-Type': 'application/x-www-form-urlencoded' // 默认值
          }
        })
        console.log(res)
      } catch (e) {
        console.log(e)
        this.$toast.fail(e)
      }
    },
    async doAdd () {
      if (!this.vh_p_number || !this.in_uid) {
        return this.$toast('未获取到通道内的待入场的访客车牌')
      }
      if (!this.visitor_name) {
        return this.$toast('请输入访客名称')
      }
      if (!this.visitor_phone) {
        return this.$toast('请输入访客联系方式')
      }
      const action = this.getResultVh
      try {
        this.$toast.loading({
          mask: true,
          message: '正在请求添加访客车...'
        })
        const client = await this.init_mqtt()
        await this.subscribe(action)
        client.callback[action] = this.getAddResult
        const res = await axios({
          url: common.cloudParkingUrl + '/s2p/' + api.urlList.add_visitor_info_with_in_uid,
          method: 'POST',
          data: {
            sid: this.$route.query.sid,
            in_uid: this.in_uid,
            in_channel_path: this.$route.query.channel_path,
            vh_p_number: this.vh_p_number,
            visitor_name: this.visitor_name,
            visitor_phone: this.visitor_phone,
            visitor_comment: this.visitor_comment,
            version: 'v2'
          },
          header: {
            // 'content-type': 'application/json' // 默认值
            'Content-Type': 'application/x-www-form-urlencoded' // 默认值
          }
        })
        const d = res && res.data
        if (d.code !== common.resCode.OK) {
          return this.$toast(d.msg)
        }
      } catch (e) {
        this.$toast('network error')
      }
    },
    getAddResult (topic, msg) {
      if (msg.code === common.resCode.OK) {
        // this.$toast.clear()
        this.$toast.success('添加成功')
        this.in_uid = ''
        this.vh_p_number = ''
        this.visitor_name = ''
        this.visitor_phone = ''
        this.visitor_comment = ''
      } else {
        this.$toast.fail('未获取到通道内的待入场的访客车牌')
      }
    }
  },
  mounted () {
    if (!this.$route.query.sid ||
      !this.$route.query.channel_path ||
      Number(this.$route.query.direction) !== 1
    ) {
      this.$toast('错误的二维码')
    } else {
      this.doGetChannelVh().catch()
    }
    // this.query_customer_by_sid(this.$route.query.sid)
  },
  beforeDestroy () {
    this.init_mqtt().then(client => {
      client.unsubscribe([this.getChannelVh, this.getResultVh])
    })
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 0.2rem;
  background: #f5f5f5;
  font-family: PingFangSC-Regular, sans-serif;
}
.content-wrap {
  margin-top: 20px;
  padding-bottom: 40px;
  background: white;
  border-radius: 6px;
}

.visitor-vh-title {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 0.24rem;
  color: #333333;
  font-weight: bold;
  text-align: center;
}
.vhNumTit {
  /deep/ .van-cell__title {
    color: #646566 !important;
  }
}
</style>
